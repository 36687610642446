/*** Modal styling ***/
.st-modal {
    .st-modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
    }

    .st-modal-container {
      background-color: #fff;
      padding: 50px;
      min-width: 300px;
      max-width: 100%;
      max-height: 100vh;
      border-radius: 4px;
      overflow-y: auto;
      box-sizing: border-box;

        @media (min-width: 992px) {
            width: 800px;
            min-width: 800px;
        }
    }

    .st-modal-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .st-modal-footer {
        display: flex;
        justify-content: center;
    }

    .st-modal-close {
      background: transparent;
      border: 0;
    }

    .st-modal-content {
      margin-top: 50px;
      margin-bottom: 50px;
      color: rgba(0,0,0,.8);
    }

    .st-modal-btn {
        width: 325px;
        height: 70px;
        background-color: var(--turquoise);
        padding: 25px;
        text-align: center;
        font-family: var(--font-family-sans-bold), sans-serif;
        font-size: 18px;
        color: var(--vertDeChrome);
        display: inline-block;
        line-height: 20px;
        text-transform: uppercase;
        border: none;
        outline: none;

        @media (min-width: 992px) {
            width: 360px;
            height: 80px;
            padding: 30px;
        }
    }

    .st-modal-btn:hover {
        text-decoration: none;
        color: var(--vertDeChrome);
        background-color: var(--hoverTurquoise);
        cursor:pointer;
    }

    .st-modal-close-cross {
        cursor:pointer;
    }

    .st-modal-close-cross img {
        pointer-events: none;
    }

    /*** Modal animation styling ***/

    @keyframes mmfadeIn {
        from { opacity: 0; }
          to { opacity: 1; }
    }

    @keyframes mmfadeOut {
        from { opacity: 1; }
          to { opacity: 0; }
    }

    @keyframes mmslideIn {
      from { transform: translateY(15%); }
        to { transform: translateY(0); }
    }

    @keyframes mmslideOut {
        from { transform: translateY(0); }
        to { transform: translateY(-10%); }
    }

    .st-micromodal-slide {
      display: none;
    }

    .st-micromodal-slide.is-open {
      display: block;
    }

    .st-micromodal-slide[aria-hidden="false"] .st-modal-overlay {
      animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    .st-micromodal-slide[aria-hidden="false"] .st-modal-container {
      animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
    }

    .st-micromodal-slide[aria-hidden="true"] .st-modal-overlay {
      animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    .st-micromodal-slide[aria-hidden="true"] .st-modal-container {
      animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
    }

    .st-micromodal-slide .st-modal-container,
    .st-micromodal-slide .st-modal-overlay {
      will-change: transform;
    }
}

/*** Modal edition styling ***/
.has-edit-mode-menu {
    .st-micromodal-slide {
        display: block;
    }

    .st-modal-overlay {
        position: relative;
    }
}

/** Style supplémentaire pour la modal sous IE **/
.st-modal-warning-ie {
    &.is-hidden {
        display: none;
    }

    .st-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .st-modal-content h2 {
        margin-top: 0px;
        margin-bottom: 1.25rem;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.375;
        font-family: "Geon Expanded Regular", sans-serif;
    }

    .st-modal-content p {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5;
        text-align: center;
        font-family: "Geon Expanded Regular", sans-serif;
    }

    .modal-warning-ie-img {
        margin: 3px;
        height: auto;
        max-height: 30px;
        max-width: 30px;
    }

    .st-modal-btn {
        background-color: #49d6c6;
        color: #fff;

        &:hover {
            color: #004a41;
            background-color: #58e5d6;
        }
    }
}